import { inject, Injectable, LOCALE_ID, signal } from '@angular/core';
import { Interest, InterestQuestion, InterestScore } from '@career-scope/models';
import { interests, interestQuestions, spanishInterestQuestions, spanishInterests } from '@career-scope/libs/data';
import { BaseInterestService } from './base-interest.service';
import { RatingOption } from '../shared/components/rating-options/rating-options.component';

@Injectable()
export class CareerScopeInterestService extends BaseInterestService {
  locale = inject(LOCALE_ID);
  interests = signal<Interest[]>(this.locale === 'en' ? interests : spanishInterests);
  interestQs = signal<InterestQuestion[]>(this.locale === 'en' ? interestQuestions : spanishInterestQuestions);
  topInterests = signal<(Interest & InterestScore)[]>([]);
  ratingConfigOptions = signal<RatingOption[]>([
    { value: 1, answerValue: 3, label: this.locale === 'en' ? 'Dislike' : 'No me gusta', emoji: 'red', color: '#C21807' },
    { value: 2, answerValue: 2, label: this.locale === 'en' ? 'Not Sure' : 'No estoy seguro', emoji: 'yellow', color: '#636363' },
    { value: 3, answerValue: 1, label: this.locale === 'en' ? 'Like' : 'Me gusta', emoji: 'green', color: '#008000' },
  ]);

  INTEREST_INVENTORY_TASK_ID = 16;

  constructor() {
    super();
  }
} 