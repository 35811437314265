import { Component, OnDestroy, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router, RouterLink } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { trigger, transition, style, animate } from '@angular/animations';
import { AuthService } from '../../_services/auth.service';
import { EvalueeService } from '../../_services/evaluee.service';
import { environment } from '../../../environments/environment';
import { AudioService } from '../../_services/audio.service';
import { FeaturesSecurityService } from '../../_services/features-security.service';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { MatSidenavContainer, MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { MatToolbar, MatToolbarRow } from '@angular/material/toolbar';
import { MatNavList, MatListItem } from '@angular/material/list';
import { MatIconButton, MatAnchor, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { AssessmentService } from '../../_services/assessment.service';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  animations: [
    trigger('fold', [
      transition(':leave', [style({ height: '*', overflow: 'hidden' }), animate('.3s ease', style({ height: 0 }))])
    ]),
    trigger('bar-fold', [
      transition(':enter', [style({ height: 0, overflow: 'hidden' }), animate('.3s ease', style({ height: '*' }))]),
      transition(':leave', [style({ height: '*', overflow: 'hidden' }), animate('.3s ease', style({ height: 0 }))])
    ]),
  ],
  imports: [NgIf, MatSidenavContainer, MatSidenav, MatToolbar, MatNavList, MatListItem, RouterLink, MatSidenavContent, MatToolbarRow, MatIconButton, MatIcon, NgTemplateOutlet, MatAnchor, MatButton, MatMenuTrigger, MatMenu, MatMenuItem, MatTooltip, AsyncPipe, MatDivider]
})
export class ShellComponent implements OnDestroy {
  private breakpointObserver = inject(BreakpointObserver);

  authSubscription: Subscription;
  currentYear = new Date().getFullYear();
  environment = environment;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );



  constructor(
    private auth: AuthService,
    public es: EvalueeService,
    public as: AssessmentService,
    public fss: FeaturesSecurityService,
    private router: Router,
    public dialog: MatDialog,
    public audio: AudioService,
  ) {
    this.authSubscription = this.auth.user$.subscribe(res => {
      if (!res) {
        this.dialog.closeAll();
        //this.router.navigateByUrl('/login');
      }
    });
  }

  signOut() {
    this.auth.signOut();
  }

  changeLanguage(language: 'en' | 'es-US') {
    if (environment.environment === 'production') {
      window.location.href = `https://app.careerscope.com/${language}/${this.router.url}`;
    } else if (environment.environment === 'staging') {
      window.location.href = `https://app-staging.careerscope.com/${language}/${this.router.url}`;
    } else if (environment.environment === 'staging-eagle') {
      window.location.href = `https://appvx-staging.careerscope.com/${language}/${this.router.url}`;
    } else if (environment.environment === 'eagle') {
      window.location.href = `https://appvx.careerscope.com/${language}/${this.router.url}`;
    } else {
      console.log('Environment not supported for changing language', environment.environment);
    }
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

  togglerSuperUserActions(enable: boolean) {
    if (this.fss.getSuperUser().value) {
      this.fss.showSuperUserActions.update(() => enable);
    }
  }
}
