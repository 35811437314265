<ng-container *ngIf="{ evaluee: this.es.evaluee | async } as obs">
  <mat-sidenav-container class="sidenav-container">
    <!-- SIDENAV -->

    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="false">
      <mat-toolbar i18n>Menu</mat-toolbar>
      <ng-container *ngIf="obs.evaluee as evaluee">
        <mat-nav-list>
          @if (es.evalueeProfileCompleted | async) {
          <a mat-list-item routerLink="interests" (click)="drawer.close()" i18n>Interests</a>
          <a mat-list-item routerLink="exercises" (click)="drawer.close()" i18n>Exercises</a>
          <a mat-list-item routerLink="results" (click)="drawer.close()" i18n>My Results</a>
          <ng-container *ngIf="(fss.evalueeFeatureFlags() | async)?.viewMyPath">
            <a mat-list-item routerLink="path" (click)="drawer.close()" i18n>My Path</a>
          </ng-container>
          }
          <a mat-list-item routerLink="profile" (click)="drawer.close(); drawer.close()" i18n>Profile</a>
          <mat-divider></mat-divider>
          <a mat-list-item (click)="changeLanguage('en'); drawer.close()" i18n>English (EN)</a>
          <a mat-list-item (click)="changeLanguage('es-US'); drawer.close()" i18n>Español (ES)</a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="login" (click)="drawer.close(); signOut()" i18n>Sign Out</a>
        </mat-nav-list>
      </ng-container>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <!-- TOP TOOLBAR-->
      <header [@fold] *ngIf="!this.as.quizInProgress()">
        <mat-toolbar>
          <mat-toolbar-row class="container">
            <ng-container *ngIf="obs.evaluee as evaluee">
              <button type="button" aria-label="Toggle sidenav" i18n-aria-label mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async" [disabled]="!obs.evaluee">
                <mat-icon aria-label="Side nav toggle icon" i18n-aria-label style="color: white">menu</mat-icon>
              </button>
            </ng-container>

            <a routerLink=""><img class="logo" src="../../../assets/images/Logo_white-gold-tight.svg" /></a>

            <span class="fill-space"></span>

            <ng-container *ngIf="(isHandset$ | async) === false">
              <ng-container *ngIf="obs.evaluee as evaluee">
                @if (es.evalueeProfileCompleted | async) {
                <div style="padding-top: 2px">
                  <a mat-button routerLink="interests" i18n>Interests</a>
                  <a mat-button routerLink="exercises" i18n>Exercises</a>
                  <a mat-button routerLink="results" i18n>My Results</a>
                  <a mat-button routerLink="path" *ngIf="(fss.evalueeFeatureFlags() | async)?.viewMyPath" i18n>My Path</a>
                </div>
                }

                <div class="profile">
                  <button mat-button (click)="audio.displayControls(!evaluee.audioEnabled)">
                    <mat-icon *ngIf="obs.evaluee?.audioEnabled">volume_up</mat-icon>
                    <mat-icon *ngIf="!obs.evaluee?.audioEnabled">volume_off</mat-icon>
                  </button>
                  <ng-container>
                    <ng-container *ngTemplateOutlet="language"></ng-container>
                  </ng-container>

                  <a mat-button [matMenuTriggerFor]="userMenu">
                    <mat-icon>perm_identity</mat-icon>
                    <span class="username">{{ evaluee.fullName }}</span>
                    <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
                  </a>
                  <mat-menu #userMenu="matMenu">
                    <a mat-menu-item routerLink="profile" i18n>My Profile</a>
                    <a mat-menu-item (click)="audio.displayControls(!evaluee.audioEnabled)" i18n>Show Audio Controls</a>
                    <a mat-menu-item *ngIf="fss.getSuperUser() | async" (click)="togglerSuperUserActions(true)" id="super-user-toggle-enable">Enable Super User</a>
                    <a mat-menu-item *ngIf="fss.getSuperUser() | async" (click)="togglerSuperUserActions(false)" id="super-user-toggle-disable">Disable Super User</a>
                    <a mat-menu-item routerLink="login" (click)="signOut()" i18n>Sign Out</a>
                  </mat-menu>

                  <mat-icon *ngIf="!environment.production" class="dev-icon" matTooltip="This is a test environment. Contact your counselor if you see this." i18n-matTooltip i18n>code</mat-icon>
                </div>
              </ng-container>
            </ng-container>
          </mat-toolbar-row>
        </mat-toolbar>
      </header>
      <div *ngIf="obs.evaluee?.audioEnabled" class="notice-bar" [@bar-fold]>
        <div class="container">
          <div class="audio-controls">
            <button mat-flat-button (click)="audio.stopAudio()" i18n><mat-icon>stop</mat-icon>Stop Audio</button>
            <button mat-flat-button style="margin-left: 1rem" (click)="audio.repeat()" i18n><mat-icon>replay</mat-icon>Repeat</button>
          </div>
        </div>
      </div>
      <!-- TRANSCLUSION -->
      <main>
        <ng-content></ng-content>
      </main>
      <footer>
        <div class="container footer">
          <p aria-label="Copyright Info" i18n>&copy; {{ currentYear }} CareerScope. All rights reserved.</p>
          <div>
            <a mat-button href="https://careerscope.com/privacy-policy/" target="_blank" i18n>Privacy</a>
            <a mat-button href="assets/pdfs/CareerScope Online Services Agreement 2023.pdf" target="_blank" i18n>Terms</a>
          </div>
        </div>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<ng-template #language>
  <button mat-button [matMenuTriggerFor]="languageMenu">
    <mat-icon>language</mat-icon>
  </button>
  <mat-menu #languageMenu="matMenu">
    <button mat-menu-item (click)="changeLanguage('en')">English (EN)</button>
    <button mat-menu-item (click)="changeLanguage('es-US')">Español (ES)</button>
  </mat-menu>
</ng-template>
