import { inject, Injectable, LOCALE_ID, signal } from '@angular/core';
import { Interest, InterestQuestion, InterestScore } from '@career-scope/models';
import { testerlyInterestQuestions, spanishTesterlyInterests, spanishTesterlyInterestQuestions, TESTERLY_INTERESTS } from '@career-scope/libs/data';
import { BaseInterestService } from './base-interest.service';
import { RatingOption } from '../shared/components/rating-options/rating-options.component';

@Injectable()
export class TesterlyInterestService extends BaseInterestService {
  locale = inject(LOCALE_ID);
  interests = signal<any[]>(this.locale === 'en' ? TESTERLY_INTERESTS : spanishTesterlyInterests);
  interestQs = signal<InterestQuestion[]>(this.locale === 'en' ? testerlyInterestQuestions : spanishTesterlyInterestQuestions);
  topInterests = signal<(Interest & InterestScore)[]>([]);
  ratingConfigOptions = signal<RatingOption[]>([
    { value: 1, answerValue: 1, label: this.locale === 'en' ? 'Strongly Dislike' : 'Muy poco interesante', emoji: 'red', color: '#C21807' },
    { value: 2, answerValue: 2, label: this.locale === 'en' ? 'Dislike' : 'Poco interesante', emoji: 'orange', color: '#E66C23' },
    { value: 3, answerValue: 3, label: this.locale === 'en' ? 'Neutral' : 'Neutro', emoji: 'yellow', color: '#636363' },
    { value: 4, answerValue: 4, label: this.locale === 'en' ? 'Like' : 'Interesante', emoji: 'light-green', color: '#4CAF50' },
    { value: 5, answerValue: 5, label: this.locale === 'en' ? 'Strongly Like' : 'Muy interesante', emoji: 'green', color: '#008000' }
  ],);
  INTEREST_INVENTORY_TASK_ID = 26;

  constructor() {
    super();
  }
}   